const dateFormatter = new Intl.DateTimeFormat("es", { day: "numeric", month: "numeric", year: "numeric" })
const weekDayFormatter = new Intl.DateTimeFormat("es", { weekday: "short" })
const timeFormatter = new Intl.DateTimeFormat("es", { hour: "numeric", minute: "numeric" })

export function formatShortWeekDay(date: Date): string {
    return weekDayFormatter.format(date).toUpperCase();
}

export function formatDate(date: Date): string {
    return dateFormatter.format(date).toUpperCase();
}

export function formatTime(date: Date): string {
    return timeFormatter.format(date);
}

export function formatDateTime(date: Date): string {
    return `${formatDate(date)} - ${formatTime(date)}`;
}
