import React, { useEffect, useMemo, useState } from 'react';
import { Button, Chip, Icon, IconButton, Text, useTheme } from 'react-native-paper';
import { NavigationWithProps } from '../../../core/Types';
import { TherapistNavParamList } from '../Therapist';
import { Image, LayoutChangeEvent, SafeAreaView, ScrollView, StyleSheet, ToastAndroid, View } from 'react-native';
import B from '../../../components/Bold';
import { useTimeSlots } from '../../../core/api/timeSlots';
import { formatTime, formatShortWeekDay, formatDate } from '../../../utils/Formatting';
import { ChipListLoader } from '../../../components/Loaders';
import { useDialog } from '../../../core/hooks/dialog';
import LoadingIcon from '../../../components/LoadingIcon';
import { serializeAppointment } from '../../../utils/Serialize';
import { TherapistModel } from '../../../core/models/TherapistModel';
import { useTherapists } from '../../../core/api/therapists';
import { getProfileImageUrl } from '../../../utils/Procedures';
import { usePublicApi } from '../../../core/api/publicApi';

enum ScrollSource {
    SCROLL = 0,
    TIME_SLOTS = 1
};

const TherapistProfile: React.FC<NavigationWithProps<{}, TherapistNavParamList, "TherapistProfile">> = ({ navigation, route }) => {

    const therapist = route.params;

    const theme = useTheme();
    const dialog = useDialog();

    const publicApi = usePublicApi();
    const therapists = useTherapists();
    const timeSlots = useTimeSlots();

    const [selectedDay, setSelectedDay] = useState<number>(-1);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<number>(-1);
    const [dayList, setDayList] = useState<Date[]>([]);
    const [timeSlotsList, setTimeSlotsList] = useState<Date[] | undefined>();
    const [loadingCreate, setLoadingCreate] = useState<boolean>(false);

    // -- EXTREME WORKAROUND FOR A BUG IN THE SCROLLVIEW --
    // The view "jumps" and glitches when the content is smaller than the scrollview.
    const [_scrollWidth, _setScrollWidth] = useState<number>(0);
    const [_timeSlotsWidth, _setTimeSlotsWidth] = useState<number>(0);
    const _canScroll = useMemo(() => _timeSlotsWidth > _scrollWidth, [_timeSlotsWidth, _scrollWidth]);
    const _onScrollLayout = (event: LayoutChangeEvent, source: ScrollSource) => {
        if (source === ScrollSource.SCROLL) {
            _setScrollWidth(event.nativeEvent.layout.width);
        } else {
            _setTimeSlotsWidth(event.nativeEvent.layout.width);
        }
    };
    // -- END OF WORKAROUND --

    const hasSelected = useMemo(() => !loadingCreate &&
        timeSlotsList != undefined &&
        selectedDay >= 0 &&
        selectedTimeSlot >= 0, [loadingCreate, timeSlotsList, selectedDay, selectedTimeSlot]);

    const fetchTimeSlots = async (date: Date) => {
        const slots = await timeSlots.getFreeTimeSlots(therapist.id, date);
        setTimeSlotsList(slots);
    }

    const registerAppointment = async () => {
        if (!hasSelected) {
            return;
        }

        const time = timeSlotsList![selectedTimeSlot];
        const ok = await dialog
            .openIconDialog("calendar-question",
                "Confirmar terapia",
                `Estás a punto se solicitar una sesión de terapia con ${therapist.full_name} ` +
                `para el día ${formatDate(dayList[selectedDay])} a las ${formatTime(time)}. ` +
                `¿Deseas continuar?`,
                "Solicitar",
                "Cancelar");

        if (!ok) {
            return;
        }

        setLoadingCreate(true);
        //TODO: Handle error
        const appointment = await therapists.createAppointment(therapist.id, time);
        setLoadingCreate(false);

        if (appointment) {
            navigation.replace("TherapistConfirmation", {
                therapist: therapist,
                appointment: serializeAppointment(appointment)
            });
        } else {
            dialog.openIconDialog("close-circle-outline",
                "Error creando cita",
                "No se ha podido solicitar la cita. Por favor, inténtalo de nuevo más tarde.");
        }
    };

    const updateSelectedDay = async (index: number) => {
        setTimeSlotsList(undefined);
        setSelectedDay(index);
        fetchTimeSlots(dayList[index]);
        setSelectedTimeSlot(-1);
    };

    const goBack = () => {
        navigation.getParent()?.navigate("Pages");
    };

    const openTherapistCalendar = () => {
        navigation.navigate("TherapistCalendar", therapist);
    };

    useEffect(() => {
        const today = new Date()
        today.setHours(0, 0, 0, 0);

        const days: Date[] = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            days.push(date);
        }

        setDayList(days);
        setSelectedDay(0);
        fetchTimeSlots(today);
    }, []);

    return (
        <SafeAreaView
            style={{
                flex: 1,
                backgroundColor: theme.colors.surfaceVariant,
                position: "relative"
            }}>
            <ScrollView>
                <IconButton style={{
                    marginTop: 50,
                    marginLeft: 20,
                }}
                    onPress={goBack}
                    icon="arrow-left" />

                <Image
                    style={{
                        width: 150,
                        height: 150,
                        borderRadius: 100,
                        alignSelf: "center",
                        marginTop: 10,
                        resizeMode: 'contain'
                    }}
                    source={getProfileImageUrl(therapist, publicApi)} />

                <Text style={{
                    fontSize: 30,
                    color: theme.colors.onSurfaceVariant,
                    textAlign: "center",
                    marginTop: 20,
                    marginHorizontal: 15
                }}>
                    <B>{therapist.full_name} </B>
                    {therapist.is_premium && <View style={{
                        margin: 10,
                    }}>
                        <Icon source="check-decagram"
                            size={20}
                            color={theme.colors.primary} />
                    </View>}
                </Text>

                <View>
                    <Text style={{
                        fontSize: 20,
                        color: theme.colors.onSurfaceVariant,
                        textAlign: "center",
                        marginTop: 20,
                    }}>
                        {therapist.speciality}
                    </Text>
                </View>

                <View style={{
                    borderTopLeftRadius: 30,
                    borderTopRightRadius: 30,
                    backgroundColor: theme.colors.surface,
                    marginTop: 80
                }}>
                    <View
                        style={{
                            paddingHorizontal: 20,
                            paddingVertical: 20
                        }}
                    >
                        <Text style={{
                            fontSize: 20,
                            color: theme.colors.onSurfaceVariant
                        }}>
                            <B>Información</B>
                        </Text>
                        <Text style={{
                            fontSize: 16,
                            color: theme.colors.onSurface,
                            marginTop: 10
                        }}>
                            {therapist.description}
                        </Text>

                        <Text style={{
                            fontSize: 20,
                            color: theme.colors.onSurfaceVariant,
                            marginTop: 20
                        }}>
                            <B>Coste</B>
                        </Text>
                        <Text style={{
                            fontSize: 16,
                            color: theme.colors.onSurface,
                            marginTop: 10
                        }}>
                            {therapist.session_cost} € / sesión
                        </Text>

                        <View style={{
                            marginTop: 20,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <Text style={{
                                fontSize: 20,
                                color: theme.colors.onSurfaceVariant
                            }}>
                                <B>Horario</B>
                            </Text>

                            <Button
                                icon="arrow-right"
                                contentStyle={{ flexDirection: 'row-reverse' }}
                                onPress={() => openTherapistCalendar()}>
                                Más días
                            </Button>
                        </View>
                        <ScrollView style={{
                            marginTop: 10,
                            flexDirection: 'row'
                        }}
                            horizontal={true}
                        >
                            {
                                dayList.map((day, index) => (
                                    <View
                                        key={index}
                                        onPointerUp={() => { updateSelectedDay(index) }}
                                        style={{
                                            borderWidth: 0.5,
                                            borderColor: theme.colors.secondary,
                                            borderRadius: 15,
                                            overflow: 'hidden',
                                            marginRight: 15,
                                            backgroundColor: selectedDay == index ? theme.colors.secondary : theme.colors.surface,
                                        }}>
                                        <Text style={{
                                            paddingVertical: 20,
                                            paddingHorizontal: 20,
                                            textAlign: 'center',
                                            fontSize: 20,
                                            color: selectedDay == index ? theme.colors.onSecondary : theme.colors.onSurface
                                        }}>
                                            {day.getDate()}
                                        </Text>
                                        <Text style={{
                                            textAlign: 'center',
                                            backgroundColor: theme.colors.secondaryContainer,
                                            color: theme.colors.onSecondaryContainer,
                                            paddingVertical: 5,
                                            fontSize: 16,
                                        }}>{formatShortWeekDay(day)}</Text>
                                    </View>
                                ))
                            }
                        </ScrollView>

                        {timeSlotsList == undefined ? <ChipListLoader /> :
                            <ScrollView style={{
                            }}
                                onLayout={(ev) => _onScrollLayout(ev, ScrollSource.SCROLL)}
                                scrollEnabled={_canScroll}
                                horizontal={true}>
                                <View style={{ flexDirection: 'row' }}
                                    onLayout={(ev) => _onScrollLayout(ev, ScrollSource.TIME_SLOTS)}>
                                    {
                                        timeSlotsList.map((slot, index) => (
                                            <Chip
                                                key={index}
                                                selected={selectedTimeSlot == index}
                                                mode='outlined'
                                                style={{
                                                    marginRight: 15,
                                                    backgroundColor: selectedTimeSlot == index ? theme.colors.secondaryContainer : theme.colors.surface,
                                                    marginVertical: 15,
                                                }}
                                                rippleColor={theme.colors.secondaryContainer}
                                                selectedColor={theme.colors.onSecondaryContainer}
                                                onPress={() => setSelectedTimeSlot(index)}>
                                                {formatTime(slot)}
                                            </Chip>
                                        ))
                                    }
                                </View>
                            </ScrollView>}

                        {
                            timeSlotsList?.length === 0 &&
                            <Text style={{
                                fontSize: 15,
                                marginVertical: 20,
                                color: theme.colors.onSurface
                            }}>
                                No hay horarios disponibles para este día
                            </Text>
                        }

                        <View style={{
                            marginTop: 30,
                            flexDirection: 'row',
                        }}>
                            <Button
                                onPress={registerAppointment}
                                disabled={!hasSelected}
                                style={{
                                    flexGrow: 1,
                                    backgroundColor: hasSelected ? theme.colors.secondary : theme.colors.surfaceDisabled,
                                    borderRadius: 30,
                                }}
                                mode='contained'
                                labelStyle={{
                                    fontSize: 18,
                                    paddingVertical: 10,
                                    color: hasSelected ? theme.colors.onSecondary : theme.colors.onSurfaceDisabled,
                                }}
                                icon='arrow-right'
                                contentStyle={{
                                    flexDirection: 'row-reverse',
                                    justifyContent: "center",
                                    alignContent: "flex-end"
                                }}>
                                Solicitar cita
                            </Button>
                            <LoadingIcon active={loadingCreate} />
                        </View>
                    </View>
                    <Image
                        style={{
                            marginTop: 30,
                            width: "100%",
                            height: 100,
                            resizeMode: 'cover'
                        }}
                        source={require("../../../../assets/img/scenes/fondo_marco_sm.png")} />
                </View>
            </ScrollView>
        </SafeAreaView >
    );
}

const style = StyleSheet.create({
    card: {
        paddingHorizontal: 20,
        marginHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 30,
        marginTop: 20
    }
});

export default TherapistProfile;
