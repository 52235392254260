export const Pet = {
    calendar: require('../../assets/img/pet/calendario.png'),
    face: require('../../assets/img/pet/cara.png'),
    reading: require('../../assets/img/pet/leyendo.png'),
    thinking: require('../../assets/img/pet/pensando.png'),
    music: require('../../assets/img/pet/musica.png'),
    laughing: require('../../assets/img/pet/riendo.png'),
    greetings: require('../../assets/img/pet/saludando.png'),
    greetingsFront: require('../../assets/img/pet/saludando_frente.png'),
    meditating: require('../../assets/img/pet/meditando.png'),
}

export const Scenes = {
    greetingsMountain: require('../../assets/img/scenes/saludando_monte.png'),
    readingLivingroom: require('../../assets/img/scenes/salon_leyendo.jpg'),
    meditatingTent: require('../../assets/img/scenes/meditando_tienda.jpg'),
}

export const Background = {
    fullscreen: require('../../assets/img/scenes/fondo_marco.png'),
    small: require('../../assets/img/scenes/fondo_marco_sm.png'),
}

export const Avatar = {
    menAvatar: require('../../assets/img/avatar/men2.png'),
    womenAvatar: require('../../assets/img/avatar/women2.png')
}
