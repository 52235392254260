import React, { useEffect, useRef, useState } from 'react';
import { Animated, ImageBackground, View } from 'react-native';

import B from '../../components/Bold';
import { Text, useTheme } from 'react-native-paper';
import GradientText from '../../components/GradientText';
import { RootNavParamList } from '../../../App';
import { NavigationWithProps } from '../../core/Types';
import { useAuth } from '../../core/api/auth';
import { CredentialResponse } from '@react-oauth/google';
import GoogleSignInButton from '../../components/GoogleSigninButton';

const LoginView: React.FC<NavigationWithProps<{}, RootNavParamList, "Login">> = ({ navigation }) => {

    const opacityAnim = useRef(new Animated.Value(0)).current;

    const theme = useTheme();
    const auth = useAuth();

    const [loading, setLoading] = useState(false);

    const onSuccess = async (credentialResponse: CredentialResponse) => {
        if (loading || !credentialResponse?.credential) {
            return onError();
        }

        setLoading(true);
        await auth.login(credentialResponse.credential)
            .then(() => {
                location.reload();
            })
            .catch(onError)
            .finally(() => {
                setLoading(false)
            });
    };

    const onError = () => {
        console.log('Error:', 'Error');
    }

    useEffect(() => {
        Animated.timing(opacityAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: false
        }).start();
    }, []);

    return (
        <Animated.View style={{ height: "100%", opacity: opacityAnim }}>
            <ImageBackground
                style={{
                    height: "100%",
                    flexDirection: 'column',
                }}
                source={require("../../../assets/img/scenes/fondo_login.png")}>
                <Text style={{
                    fontSize: 30,
                    textAlign: 'center',
                    marginTop: "29%"
                }}>
                    Bienvenid@, soy <GradientText style={{
                        fontSize: 30,
                        fontWeight: 'bold',
                    }} color1={'#3e7200'} color2={'#112D00'}><B>Kyra</B></GradientText>
                </Text>
                <View style={{
                    marginTop: "auto",
                    marginBottom: 90
                }}>
                    <Text style={{
                        fontSize: 20,
                        textAlign: 'center',
                        marginBottom: 10,
                        color: theme.colors.onTertiaryContainer,
                    }}>
                        <B>Inicia sesión para continuar</B>
                    </Text>
                    <View style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}>
                        <GoogleSignInButton
                            onSuccess={onSuccess}
                            onError={onError}
                            loading={loading}
                        />
                    </View>
                </View>
            </ImageBackground>
        </Animated.View >
    );
}

export default LoginView;
