/**
 * @format
 */

import { AppRegistry } from "react-native";
import App from "./App";
import appName from "./app.json";
import { PaperProvider } from "react-native-paper";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LightTheme from "./src/themes/light";
// import DarkTheme from "./src/themes/dark";
import { SessionProvider } from "./src/core/provider/SessionProvider";
import { DialogProvider } from "./src/core/provider/DialogProvider";
import { AppStoreProvider } from "./src/core/provider/AppStoreProvider";

Date.prototype.toISOString = function () {
    const tzo = -this.getTimezoneOffset(),
        dif = tzo >= 0 ? "+" : "-",
        pad = function (num) {
            return (num < 10 ? "0" : "") + num;
        };

    return (
        this.getFullYear() +
        "-" +
        pad(this.getMonth() + 1) +
        "-" +
        pad(this.getDate()) +
        "T" +
        pad(this.getHours()) +
        ":" +
        pad(this.getMinutes()) +
        ":" +
        pad(this.getSeconds()) +
        dif +
        pad(Math.floor(Math.abs(tzo) / 60)) +
        ":" +
        pad(Math.abs(tzo) % 60)
    );
};
const Main = () => {
    return (
        <PaperProvider theme={LightTheme}>
            <GoogleOAuthProvider clientId="1051949083721-oip532re4khnfa9gk0v5t3h92ol67jlg.apps.googleusercontent.com">
                <SessionProvider>
                    <AppStoreProvider>
                        <DialogProvider>
                            <style type="text/css">
                                {`@font-face {
                                font-family: 'MaterialCommunityIcons';
                                src: url(${require("react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf")}) format('truetype');
                            }`}
                            </style>
                            <App />
                        </DialogProvider>
                    </AppStoreProvider>
                </SessionProvider>
            </GoogleOAuthProvider>
        </PaperProvider>
    );
};

AppRegistry.registerComponent(appName, () => Main);
AppRegistry.runApplication(appName, {
    initialProps: {},
    rootTag: document.getElementById("app-root")
});
