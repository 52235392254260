import { ImageSourcePropType } from "react-native";
import { BASE_API } from "../hooks/http";


export const usePublicApi = () => {

    const getTherapistProfileImageUrl = (therapistId: number): ImageSourcePropType => {
        return { uri: BASE_API + `/publicapi/therapists/${therapistId}/profile/photo` };
    };

    return {
        getTherapistProfileImageUrl
    };
};
