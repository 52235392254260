import { useContext } from "react";
import { SessionContext } from "../provider/SessionProvider";
import { AppUser, Session } from "../models/Session";
import { ApiResponse, AuthResponse } from "../models/ApiResponse";
import { BASE_API } from "../hooks/http";
import { getGoogleUser } from "../jwt/jwt";

export const useAuth = () => {
    const session = useContext(SessionContext);

    if (!session.updateSession) {
        throw new Error('useSession must be used within a SessionProvider');
    }

    const login = async (credential: string): Promise<Session | undefined> => {
        let user;
        try {
            user = getGoogleUser(credential);
        } catch (error) {
            return undefined;
        }

        const url = `${BASE_API}/userapi/auth/authenticate`;
        return fetch(
            url,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ credential: credential })
            })
            .then((response) => response.json())
            .then(async (data: ApiResponse<AuthResponse>) => {
                const newSession: Session = { accessToken: data.data!.accessToken, idToken: credential, user: new AppUser(user) };
                session?.updateSession?.(newSession);
                return newSession;
            })
            .catch((error) => {
                return undefined;
            });
    };

    const logout = async (): Promise<void> => {
        session?.clearSession?.();
        location.replace('/');
    };

    return {
        login,
        logout
    };
};
