export interface User {
    id: string;
    name: string | null;
    email: string;
    picture: string | null;
    familyName: string | null;
    givenName: string | null;
};

export class AppUser implements User {
    id: string;
    name: string | null;
    email: string;
    picture: string | null;
    familyName: string | null;
    givenName: string | null;

    public get shortName(): string | null {
        return this.name?.split(" ")[0] ?? null;
    }

    constructor(user: User) {
        this.id = user.id;
        this.name = user.name;
        this.email = user.email;
        this.picture = user.picture;
        this.familyName = user.familyName;
        this.givenName = user.givenName;
    }
}

export interface Session {
    accessToken: string;
    idToken: string;
    user: AppUser; //TODO: may be we can create a common interface for joining apple and google user in future
}
