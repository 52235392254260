import { ReactNode, createContext, useState } from "react";
import { MyTherapist } from "../models/TherapistModel";


type AppStoreContextProps = {
    myTherapist?: {
        value: MyTherapist | undefined;
        set: React.Dispatch<React.SetStateAction<MyTherapist | undefined>>;
    }
}

export const AppStoreContext = createContext<AppStoreContextProps>({});

export const AppStoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [myTherapist, setMyTherapist] = useState<MyTherapist | undefined>(undefined);

    const obj = {
        myTherapist: {
            value: myTherapist,
            set: setMyTherapist
        }
    };

    return (
        <AppStoreContext.Provider value={obj}>
            {children}
        </AppStoreContext.Provider>
    );
}
