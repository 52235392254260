import { Gender } from "../core/models/enums";
import { TherapistModel } from "../core/models/TherapistModel";
import { Avatar } from "../assets/images";
import { ImageSourcePropType } from "react-native";
import { AppointmentModel } from "../core/models/AppointmentModel";
import { appointmentStatusToString } from "./EnumToString";

export function getProfileImageUrl(profile: TherapistModel,
    publicApiInstance: { getTherapistProfileImageUrl: (therapistId: number) => ImageSourcePropType }): ImageSourcePropType {
    if (profile.has_photo) {
        return publicApiInstance.getTherapistProfileImageUrl(profile.id);
    }

    if (profile.gender == Gender.Women) {
        return Avatar.womenAvatar;
    }

    return Avatar.menAvatar;
}

export function getAppointmentStatus(appointment: AppointmentModel) {
    return appointment.isInProgress ? "En progreso"
        : appointment.isCompleted ? "Completada"
            : appointmentStatusToString(appointment!.status);
}
