import { MD3LightTheme, MD3Theme, adaptNavigationTheme, configureFonts } from "react-native-paper";
import { MD3Type, NavigationTheme } from "react-native-paper/lib/typescript/types";
import {
    DarkTheme as NavigationDarkTheme,
    DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';
import '../styles/fonts.css';

const navigationTheme = adaptNavigationTheme({
    reactNavigationLight: NavigationDefaultTheme,
    reactNavigationDark: NavigationDarkTheme,
});

const fontConfig: Partial<MD3Type> = {
    fontFamily: "Nunito-Regular",
};


const LightTheme: MD3Theme & NavigationTheme = {
    ...MD3LightTheme,
    ...navigationTheme.LightTheme,

    colors: {
        ...MD3LightTheme.colors,
        ...navigationTheme.LightTheme.colors,
        "primary": "rgb(57, 106, 0)",
        "onPrimary": "rgb(255, 255, 255)",
        "primaryContainer": "rgb(182, 244, 124)",
        "onPrimaryContainer": "rgb(13, 32, 0)",
        "secondary": "rgb(151, 72, 17)",
        "onSecondary": "rgb(255, 255, 255)",
        "secondaryContainer": "rgb(255, 219, 202)",
        "onSecondaryContainer": "rgb(51, 18, 0)",
        "tertiary": "rgb(70, 89, 169)",
        "onTertiary": "rgb(255, 255, 255)",
        "tertiaryContainer": "rgb(221, 225, 255)",
        "onTertiaryContainer": "rgb(0, 19, 85)",
        "error": "rgb(186, 26, 26)",
        "onError": "rgb(255, 255, 255)",
        "errorContainer": "rgb(255, 218, 214)",
        "onErrorContainer": "rgb(65, 0, 2)",
        "background": "rgb(253, 252, 245)",
        "onBackground": "rgb(27, 28, 24)",
        "surface": "rgb(253, 252, 245)",
        "onSurface": "rgb(27, 28, 24)",
        "surfaceVariant": "rgb(224, 228, 214)",
        "onSurfaceVariant": "rgb(68, 72, 62)",
        "outline": "rgb(116, 121, 109)",
        "outlineVariant": "rgb(196, 200, 186)",
        "shadow": "rgb(0, 0, 0)",
        "scrim": "rgb(0, 0, 0)",
        "inverseSurface": "rgb(47, 49, 44)",
        "inverseOnSurface": "rgb(242, 241, 234)",
        "inversePrimary": "rgb(155, 215, 99)",
        "elevation": {
            "level0": "transparent",
            "level1": "rgb(243, 245, 233)",
            "level2": "rgb(237, 240, 225)",
            "level3": "rgb(231, 236, 218)",
            "level4": "rgb(230, 235, 216)",
            "level5": "rgb(226, 232, 211)"
        },
        "surfaceDisabled": "rgba(27, 28, 24, 0.12)",
        "onSurfaceDisabled": "rgba(27, 28, 24, 0.38)",
        "backdrop": "rgba(46, 50, 40, 0.4)"
    },

    fonts: configureFonts({ config: fontConfig, isV3: true }),
    dark: false,
};

export default LightTheme
