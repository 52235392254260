import React, { useContext, useEffect, useState } from 'react';
import { Image, ImageBackground, NativeScrollEvent, RefreshControl, SafeAreaView, ScrollView, StatusBar, View } from 'react-native';
import { Button, Text, useTheme } from 'react-native-paper';
import B from '../../../components/Bold';
import { NavigationWithProps } from '../../../core/Types';
import { PagesNavParamList } from '../Pages';
import { useAppointments } from '../../../core/api/appointments';
import { AppointmentModel, IAppointment } from '../../../core/models/AppointmentModel';
import AppointmentCard from '../../../components/AppointmentCard';
import { Pet } from '../../../assets/images';
import { CardListLoader } from '../../../components/Loaders';
import { AppStoreContext } from '../../../core/provider/AppStoreProvider';
import { useDialog } from '../../../core/hooks/dialog';
import { useTherapists } from '../../../core/api/therapists';
import { PaginatedResponse } from '../../../core/models/ApiResponse';
import { AppointmentStatus } from '../../../core/models/enums';
import { Scenes } from '../../../assets/images';

function today() {
    const start = new Date();
    const end = new Date(start);

    start.setHours(start.getHours() - 1);
    end.setHours(23, 59, 59, 999);

    return [start, end];
};

const Appointments: React.FC<NavigationWithProps<{}, PagesNavParamList, "Appointments", "AppNavigator">> = ({ navigation, route }) => {

    const theme = useTheme();
    const dialog = useDialog();

    const appointments = useAppointments();
    const therapists = useTherapists();

    const appContext = useContext(AppStoreContext);

    const [todayAppointmentList, setTodayAppointmentList] = useState<PaginatedResponse<AppointmentModel> | undefined>(undefined);
    const [pendingAppointmentList, setPendingAppointmentList] = useState<PaginatedResponse<AppointmentModel> | undefined>(undefined);
    const [confirmedAppointmentList, setConfirmedAppointmentList] = useState<PaginatedResponse<AppointmentModel> | undefined>(undefined);
    const [completedAppointmentList, setCompletedAppointmentList] = useState<PaginatedResponse<AppointmentModel> | undefined>(undefined);

    const [refreshing, setRefreshing] = useState(false)
    const [loadingNextPage, setLoadingNextPage] = useState(false);

    const fetchTodayAppointments = async (page: number = 1) => {
        if (page == 1) {
            setTodayAppointmentList(undefined);
        }

        const [start, end] = today();
        const apps = await appointments.listAppointments({
            statuses: [AppointmentStatus.Confirmed],
            timeSlotOrder: "asc",
            startDate: start,
            endDate: end,
            pageNum: page,
            pageSize: 1
        });
        if (page > 1) {
            apps.items = todayAppointmentList?.items.concat(apps.items) ?? [];
        }

        setTodayAppointmentList(apps);
    }

    const fetchPendingAppointments = async (page: number = 1) => {
        if (page == 1) {
            setPendingAppointmentList(undefined);
        }

        const apps = await appointments.listAppointments({
            statuses: [AppointmentStatus.PendingConfirmation],
            timeSlotOrder: "asc",
            startDate: new Date(),
            pageNum: page,
            pageSize: 10
        });
        if (page > 1) {
            apps.items = pendingAppointmentList?.items.concat(apps.items) ?? [];
        }

        setPendingAppointmentList(apps);
    };

    const fetchConfirmedAppointments = async (page: number = 1) => {
        if (page == 1) {
            setConfirmedAppointmentList(undefined);
        }

        const [_, end] = today();
        const apps = await appointments.listAppointments({
            statuses: [AppointmentStatus.Confirmed],
            startDate: end,
            timeSlotOrder: "asc",
            pageNum: page,
            pageSize: 10
        });
        if (page > 1) {
            apps.items = confirmedAppointmentList?.items.concat(apps.items) ?? [];
        }

        setConfirmedAppointmentList(apps);
    }

    const fetchCompletedAppointments = async (page: number = 1) => {
        if (page == 1) {
            setCompletedAppointmentList(undefined);
        }

        const [start, _] = today();
        const apps = await appointments.listAppointments({
            statuses: [AppointmentStatus.Confirmed],
            endDate: start,
            timeSlotOrder: "desc",
            pageNum: page,
            pageSize: 10
        });
        if (page > 1) {
            apps.items = completedAppointmentList?.items.concat(apps.items) ?? [];
        }

        setCompletedAppointmentList(apps);
    }

    const onRefresh = async () => {
        setRefreshing(true);
        await Promise.all([
            fetchTodayAppointments(),
            fetchPendingAppointments(),
            fetchConfirmedAppointments(),
            fetchCompletedAppointments()
        ]);
        setRefreshing(false);
    };

    const onappointmentCancel = async (appointment: IAppointment) => {
        const ok = await dialog.openIconDialog('alert-outline',
            'Cancelar cita', '¿Estas seguro de que deseas cancelar esta cita?.', "Aceptar", "Salir");

        if (ok) {
            await therapists.cancelAppointment(appointment.therapist_id, appointment.id);
        }
    }

    useEffect(() => {
        fetchTodayAppointments();
        fetchPendingAppointments();
        fetchConfirmedAppointments();
        fetchCompletedAppointments();
    }, [appContext.myTherapist?.value]);

    return (
        <SafeAreaView style={{
            flex: 1,
            backgroundColor: theme.colors.surfaceVariant,
            position: "relative"
        }}>
            <StatusBar
                translucent
                barStyle={theme.dark ? "light-content" : "dark-content"}
                backgroundColor="transparent"
            />

            <View>
                <ImageBackground
                    style={{
                        width: "100%",
                        height: 230,
                        gap: 15,
                    }}
                    imageStyle={{ resizeMode: 'cover' }}
                    source={Scenes.readingLivingroom}>
                </ImageBackground>

                <View style={{
                    alignItems: "center",
                    paddingTop: 12,
                    borderTopColor: theme.colors.secondaryContainer,
                    borderTopWidth: 0.5,
                }}>
                    <Text style={{ fontSize: 25, color: theme.colors.onSurfaceVariant }}>
                        <B>Mis citas</B>
                    </Text>
                </View>
            </View>

            <ScrollView style={{
                position: 'absolute',
                height: "100%",
                width: "100%",
            }}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                }
            >
                <View style={{ height: 300 }} />
                <View style={{
                    paddingHorizontal: 20,
                    paddingTop: 20,
                    borderTopEndRadius: 30,
                    borderTopStartRadius: 30,
                    backgroundColor: theme.colors.surface,
                    minHeight: 490
                }}>

                    {todayAppointmentList == undefined || pendingAppointmentList == undefined || completedAppointmentList == undefined ?
                        <>
                            <CardListLoader />
                            <CardListLoader />
                        </> :
                        <>
                            {todayAppointmentList?.items?.length !== 0 && <>
                                <Text style={{ fontSize: 20, marginBottom: 20 }}><B>Cita para hoy</B></Text>
                                {todayAppointmentList?.items.map((app) => (
                                    <AppointmentCard
                                        key={app.id}
                                        appointment={app}
                                        onCancelPress={onappointmentCancel} />
                                ))}
                            </>}

                            {pendingAppointmentList?.items?.length !== 0 &&
                                <>
                                    <Text style={{ fontSize: 20, marginBottom: 20 }}><B>Pendientes de confirmar</B></Text>
                                    {pendingAppointmentList?.items.map((app) => (
                                        <AppointmentCard
                                            key={app.id}
                                            appointment={app}
                                            onCancelPress={onappointmentCancel} />
                                    ))}
                                    {pendingAppointmentList?.hasNextPage === true && <Button
                                        mode="text"
                                        onPress={() => fetchPendingAppointments(pendingAppointmentList.currentPage + 1)}>
                                        Ver más
                                    </Button>}
                                </>
                            }

                            {confirmedAppointmentList?.items?.length !== 0 &&
                                <>
                                    <Text style={{ fontSize: 20, marginBottom: 20 }}><B>Confirmadas</B></Text>
                                    {confirmedAppointmentList?.items.map((app) => (
                                        <AppointmentCard
                                            key={app.id}
                                            appointment={app}
                                            onCancelPress={onappointmentCancel} />
                                    ))}
                                    {confirmedAppointmentList?.hasNextPage === true && <Button
                                        mode="text"
                                        onPress={() => fetchConfirmedAppointments(confirmedAppointmentList.currentPage + 1)}>
                                        Ver más
                                    </Button>}
                                </>
                            }

                            {completedAppointmentList?.items?.length !== 0 &&
                                <>
                                    <Text style={{ fontSize: 20, marginBottom: 20 }}><B>Completadas</B></Text>
                                    {completedAppointmentList?.items.map((app) => (
                                        <AppointmentCard
                                            key={app.id}
                                            appointment={app}
                                            onCancelPress={onappointmentCancel} />
                                    ))}
                                    {completedAppointmentList?.hasNextPage === true && <Button
                                        style={{ marginBottom: 20 }}
                                        mode="text"
                                        onPress={() => fetchCompletedAppointments(completedAppointmentList.currentPage + 1)}>
                                        Ver más
                                    </Button>}
                                </>
                            }

                            {todayAppointmentList?.items?.length === 0 &&
                                pendingAppointmentList?.items?.length === 0 &&
                                confirmedAppointmentList?.items?.length === 0 &&
                                completedAppointmentList?.items?.length === 0 &&
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 20
                                    }}>
                                    <Image
                                        style={{
                                            height: 150,
                                            width: 150,
                                        }}
                                        source={Pet.calendar} />

                                    <Text style={{ fontSize: 20, textAlign: 'center' }}>
                                        Todavía no tienes citas programadas, ¡Empieza a buscar un terapeuta!
                                    </Text>
                                </View>
                            }
                        </>}
                    {
                        loadingNextPage &&
                        <CardListLoader />
                    }
                </View>
            </ScrollView>
        </SafeAreaView >
    );
}

export default Appointments;
