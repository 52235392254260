import { BASE_API, useHttp } from "../hooks/http";
import { OnboardingRequest } from "../models/ApiResponse";


export const useOnboarding = () => {
    const http = useHttp(BASE_API, true);

    const isFilled = async (): Promise<boolean> => {
        const response = await http.get<boolean>('/userapi/onboarding/is-filled');
        return response?.data || false;
    }

    const onboard = async (request: OnboardingRequest): Promise<boolean> => {
        const response = await http.post<boolean>('/userapi/onboarding/', request);
        return response?.ok || false;
    }

    return {
        isFilled,
        onboard
    };
};
