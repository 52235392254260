import { getPayload } from "../../utils/Jwt";
import { BASE_API, useHttp } from "../hooks/http";
import { IPaginatedResponse, PaginatedResponse } from "../models/ApiResponse";
import { AppointmentModel, IAppointment } from "../models/AppointmentModel";
import { AppointmentStatus } from "../models/enums";
import { MeetingTokenResponse } from "../models/MeetingTokenResponse";

const BASE_MEETING_URL = "https://meet.psicopro.co";

export const useAppointments = () => {
    const http = useHttp(BASE_API, true);

    const listAppointments = async (filters: {
        statuses?: AppointmentStatus[],
        startDate?: Date,
        endDate?: Date,
        timeSlotOrder?: "asc" | "desc",
        createOrder?: "asc" | "desc"
        pageNum: number,
        pageSize: number,
    } = { pageNum: 1, pageSize: 10 }): Promise<PaginatedResponse<AppointmentModel>> => {
        return await http.get<IPaginatedResponse<IAppointment>>('/userapi/appointments/', {
            page_num: filters.pageNum.toString(),
            page_size: filters.pageSize.toString(),
            statuses: filters.statuses?.join(','),
            start_date: filters.startDate?.toISOString(),
            end_date: filters.endDate?.toISOString(),
            time_slot_order: filters.timeSlotOrder,
            create_order: filters.createOrder
        })
            .then((response) => {
                const data = response?.data ?? { items: [], total: 0 };

                data.items = data.items.map((app) => {
                    app.time_slot = new Date(app.time_slot);
                    app.created_at = new Date(app.created_at);

                    return new AppointmentModel(app);
                });

                return new PaginatedResponse<AppointmentModel>(<IPaginatedResponse<AppointmentModel>>data, filters.pageNum, filters.pageSize);
            }).catch((error) => {
                if (error.status === 404) {
                    return new PaginatedResponse<AppointmentModel>({ items: [], total: 0 }, filters.pageNum, filters.pageSize);
                }

                throw error;
            });
    }

    const getAppointmentMeetingUrl = async (therapistId: number, appointmentId: number): Promise<string> => {
        const token = await http.get<MeetingTokenResponse>(`/userapi/therapists/${therapistId}/appointments/${appointmentId}/token`)
            .then((response) => response?.data || '');

        if (!token) {
            throw new Error("Failed to get meeting token");
        }

        const payload = getPayload(token.token);
        return `${BASE_MEETING_URL}/${payload.room}?jwt=${token.token}`;
    };

    return {
        listAppointments,
        getAppointmentMeetingUrl
    };
};
