import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import React from "react";
import { Image, StyleSheet, View } from "react-native";

import { useTheme } from "react-native-paper";

type Props = {
    onSuccess: (credentialResponse: CredentialResponse) => void;
    onError: () => void;
    loading: boolean;
}
const GoogleSignInButton: React.FC<Props> = ({ onSuccess, onError, loading }) => {
    const theme = useTheme();

    return <View style={styles.buttonContainer}>
        <GoogleLogin
            shape="circle"
            context="use"
            text="continue_with"
            theme="filled_black"
            onSuccess={onSuccess}
            onError={onError}
        />
        {loading && <Image aria-disabled={loading} source={require("../../assets/img/icons/loading.gif")} style={styles.loadingIcon} />}
    </View>
};

const styles = StyleSheet.create({
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 10
    },
    googleLogo: {
        objectFit: "contain",
        width: 18,
        height: 18,
        marginEnd: 30
    },
    loadingIcon: {
        width: 35,
        height: 35
    }
});

export default GoogleSignInButton;
