import { AppointmentModel, IAppointment } from "./AppointmentModel";
import { Gender } from "./enums";

export interface TherapistModel {
    id: number;
    full_name: string;
    gender: Gender;
    speciality: string;
    description: string;
    session_cost: number;
    has_photo: boolean;
    is_premium: boolean;
}

export interface IMyTherapist {
    therapist: TherapistModel;
    next_appointment?: IAppointment;
}

export class MyTherapist implements IMyTherapist {
    therapist: TherapistModel;
    next_appointment?: AppointmentModel;

    constructor(myTherapist: IMyTherapist) {
        this.therapist = myTherapist.therapist;
        this.next_appointment = myTherapist.next_appointment ? new AppointmentModel(myTherapist.next_appointment) : undefined;
    }
}
