import { AppointmentModel, IAppointment } from "../core/models/AppointmentModel";

export type SerializedAppointment = Omit<IAppointment, "time_slot" | "created_at"> & {
    time_slot: string;
    created_at: string;
};

export function serializeAppointment(object: IAppointment): SerializedAppointment {
    return {
        ...object,
        time_slot: object.time_slot.toISOString(),
        created_at: object.created_at.toISOString(),
    };
}

export function deserializeAppointment(object: SerializedAppointment): AppointmentModel {
    return new AppointmentModel({
        ...object,
        time_slot: new Date(object.time_slot),
        created_at: new Date(object.created_at),
    });
}
