export enum Gender {
    Men = 1,
    Women = 2,
    Other = 3
}

export enum AppointmentStatus {
    PendingConfirmation = 1,
    PendingPayment = 2,
    Confirmed = 3,
    CancelledByUser = 4,
    CancelledByTherapist = 5
}

export enum Concern {
    MentalHealth = 1,
    PhysicalHealth = 2,
    Nutrition = 3,
    Sleep = 4,
    Stress = 5,
    Anxiety = 6,
    Depression = 7,
    Other = 8
}

export enum Language {
    ES = 1,
    EN = 2
}
