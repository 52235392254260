import { BASE_API, useHttp } from "../hooks/http";


export const useTimeSlots = () => {
    const http = useHttp(BASE_API, true);

    const getFreeTimeSlots = async (therapistId: number, date: Date): Promise<Date[]> => {
        const response = await http.get<Date[]>(`/userapi/therapists/${therapistId}/slots/`, {
            date: date.toISOString()
        }).then((response) => {
            const dates = response?.data || [];
            return dates.map((date) => new Date(date));
        });
        return response;
    }

    return {
        getFreeTimeSlots
    };
};
