import { useContext } from 'react';
import { DialogContext } from '../provider/DialogProvider';

export const useDialog = () => {

    const { showDialog, showIconDialog } = useContext(DialogContext);

    const openDialog = (title: string, content: string, accept?: string, decline?: string) => {
        return showDialog(title, content, accept, decline);
    }

    const openIconDialog = (icon: string, title: string, content: string, accept?: string, decline?: string) => {
        return showIconDialog(icon, title, content, accept, decline);
    }

    return {
        openDialog,
        openIconDialog
    };
};
