import { ReactNode, createContext, useEffect, useState } from "react";
import { AppUser, Session } from "../models/Session";
import { getGoogleUser } from "../jwt/jwt";


type SessionContextProps = {
    session?: Session;
    updateSession?: (session?: Session) => void;
    clearSession?: () => void;
}

export const SessionContext = createContext<SessionContextProps>({});

export const SessionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [initializing, setInitializing] = useState<boolean>(true);
    const [session, setSession] = useState<Session | undefined>();

    const attemptSessionRestore = () => {
        const accessToken = sessionStorage.getItem("access_token");
        const idToken = sessionStorage.getItem("id_token");

        if (!accessToken || !idToken) {
            return;
        }

        //TODO: Check token expiration
        try {
            const user = getGoogleUser(idToken)
            setSession({ accessToken, idToken, user: new AppUser(user) });
        } catch (error) {
            console.error('Error:', error);
            clearSession();
        }
    };

    const setSessionAndStore = (session?: Session) => {
        if (!session) {
            sessionStorage.removeItem("access_token");
            sessionStorage.removeItem("id_token");
        } else {
            sessionStorage.setItem("access_token", session.accessToken);
            sessionStorage.setItem("id_token", session.idToken);
        }

        setSession(session);
    };

    const clearSession = () => {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("id_token");
        setSession(undefined);
    };

    useEffect(() => {
        attemptSessionRestore();
        setInitializing(false);
    }, []);

    return (
        <SessionContext.Provider value={{ session, updateSession: setSessionAndStore, clearSession }}>
            {!initializing && children}
        </SessionContext.Provider>
    );
}
