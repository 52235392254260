import { Image, StyleSheet } from "react-native";

type Props = {
    active: boolean
}

const LoadingIcon: React.FC<Props> = (props: Props) => (
    <>
        {props.active && <Image aria-disabled={props.active} source={require("../../assets/img/icons/loading.gif")} style={styles.loadingIcon} />}
    </>
);

const styles = StyleSheet.create({
    loadingIcon: {
        alignSelf: "center",
        width: 35,
        height: 35
    }
});

export default LoadingIcon;
