import ContentLoader, { Circle, Rect } from "react-content-loader/native";

export const ChipListLoader = (props: any) => (
    <ContentLoader
        speed={2}
        width={476}
        height={42}
        viewBox="0 0 476 42"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <Rect x="6" y="9" rx="0" ry="0" width="64" height="32" />
        <Rect x="84" y="9" rx="0" ry="0" width="64" height="32" />
        <Rect x="164" y="9" rx="0" ry="0" width="64" height="32" />
        <Rect x="245" y="9" rx="0" ry="0" width="64" height="32" />
        <Rect x="328" y="9" rx="0" ry="0" width="64" height="32" />
    </ContentLoader>
)

export const CardListLoader = (props: any) => (
    <ContentLoader
        speed={2}
        width={476}
        height={124}
        viewBox="0 0 476 124"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <Circle cx="38" cy="51" r="30" />
        <Rect x="81" y="21" rx="5" ry="5" width="110" height="12" />
        <Rect x="81" y="50" rx="5" ry="5" width="250" height="7" />
        <Rect x="81" y="70" rx="5" ry="5" width="250" height="7" />
    </ContentLoader>
)
