import React, { PropsWithChildren } from "react";

import { Text, TextStyle } from "react-native";

type Props = PropsWithChildren<{
    style?: TextStyle
}>

const B: React.FC<Props> = ({ children, style }) => {
    return (<Text style={{ fontFamily: 'Nunito-Bold', ...style }}>{children}</Text>);
};

export default B;
