import React from 'react';
import { NavigationWithProps } from '../../core/Types';
import { RootNavParamList } from '../../../App';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import TherapistProfile from './children/TherapistProfile';
import { TherapistModel } from '../../core/models/TherapistModel';
import TherapistConfirmation from './children/TherapistConfirmation';
import TherapistCalendar from './children/TherapistCalendar';
import { SerializedAppointment } from '../../utils/Serialize';

export type TherapistNavParamList = {
    TherapistProfile: TherapistModel,
    TherapistCalendar: TherapistModel,
    TherapistConfirmation: {
        therapist: TherapistModel,
        appointment: SerializedAppointment
    }
};

const Stack = createNativeStackNavigator<TherapistNavParamList>();

const Therapist: React.FC<NavigationWithProps<{}, RootNavParamList, "Therapist">> = ({ navigation, route }) => {

    return (
        <Stack.Navigator id='TherapistNavigator'
            screenOptions={
                { headerShown: false }
            }
            initialRouteName='TherapistProfile'>
            <Stack.Screen
                name="TherapistProfile"
                component={TherapistProfile}
                initialParams={route.params}
            />
            <Stack.Screen
                name="TherapistCalendar"
                component={TherapistCalendar}
                initialParams={route.params}
            />
            <Stack.Screen
                name="TherapistConfirmation"
                component={TherapistConfirmation}
            />
        </Stack.Navigator>
    );
}

export default Therapist;
