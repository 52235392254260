import React, { PropsWithChildren } from "react";
import { Text } from "react-native-paper";
import { TextStyle } from "react-native";

type Props = PropsWithChildren<{
    color1: string | number,
    color2: string | number,
    style?: TextStyle
}>

const GradientText: React.FC<Props> = ({ children, style, color1, color2 }) => {
    return (
        <>
            <style>
                {`
                .gradientText span {
                    background: linear-gradient(90deg, ${color1}, ${color2});
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                `}
            </style>
            <span className="gradientText">
                <Text children={children} style={[style]} />
            </span>
        </>
    );
};

export default GradientText;
